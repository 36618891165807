#tradition{
    background-color: #FB4622;
    width: 100vw;
    padding: 4rem 0;
    display: flex;
    justify-content: space-between;
}

#tradition-container{
    display: flex;
    flex-direction: column;
    justify-content: baseline;
    align-items: end;
    padding: 3rem;
    width: 50vw;
}


.tradition-txt{
    color: white;
    margin: 10px;
    font-family: "futura-pt", sans-serif;
    font-size: 20px;
    text-align: right;
    width: 500px;
    max-width: calc(50vw - 6rem);
}

#tradition-img{
    height: 500px;
    object-fit: cover;
    object-position: center;
    width: 50vw;
    max-width: 700px;
    border-radius:  2px 0 0 2px;
    margin: auto 0;
}

#tradition-header{
    font-family: 'BigSans';
    color: #b0f6fa;
    font-size: 56px;
    margin: 0;
    text-align: right;
}

#twist{
    color: #77FD78;
    font-family: 'DesMontille';
    font-size: 128px;
    position: relative;
    top: -60px;
    left: 10px;
    margin: 0;
    height: 100px;
}

@media only screen and (max-width: 950px) {
    #tradition-header{
        font-size: 6vw;
    }
    #twist{
        font-size: 10vw;
        top: -40px;
    }
}

@media only screen and (max-width: 750px) {
    #tradition{
        flex-direction: column;
        align-items: center;
    }

    #tradition-img{
        width: 100vw;
        max-width: 100vw;
    }

    #tradition-container{
      
        align-items: center;
        padding: 1rem;
        width: 100vw;
        text-align: center;
    }

    .tradition-txt{
        text-align: center;
        width: 100%;
        max-width: 100%;
    }

    #tradition-header{
        font-size: 12vw;
        text-align: center;
    }
    #twist{
        font-size: 20vw;
        top: -40px;
    }
}