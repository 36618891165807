#product-scroller{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    background-color: #FB4622;
}

#our-txt{
    font-family: 'DesMontille';
    color: #b0f6fa;
    font-size: 128px;
    margin: 0;
}

#product-txt{
    font-family: 'BigSans';
    color: #77FD78;
    font-size: 64px;
    margin: 0;
    position: relative;
    top: -45px;

}

#products-desc{
    width: 650px;
    max-width: 90vw;
    margin: 0 auto;
    color: #fff;
    text-align: center;
    position: relative;
    top: -30px;
    font-family: "futura-pt", sans-serif;
    font-size: 20px;
}

.product-slider-img{
    width: 80%;
}

#product-slider{
    display: flex;
    justify-content: space-between;
    overflow: scroll;
    margin: 2rem auto;
    width: 95vw;
    max-width: 1000px;
}

.product-slider-name{
    font-family: 'BigSans';
    color: #FB4622;
    text-align: center;
    font-size: 26px;
    width: 100%;
}

.product-slider-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 22%;
}

.product-slider-container:hover{
    cursor: pointer;
}

@media only screen and (max-width: 850px) {
    .product-slider-name{
        font-family: 'Futura-pt', sans-serif;
        font-weight: 900;
        letter-spacing: 2px;
        color: #FB4622;
        text-align: center;
        font-size: 16px;
        width: 100%;
        text-transform: uppercase;
    }

    #our-txt{
        font-size: 20vw;
        z-index: 4;
    }
    
    #product-txt{
        z-index: 3;
        font-size: 10vw;
        top: -20px;
    
    }

    #products-desc{
        top: 0px
    }
  }