.modal{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(17,17,17,0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;

}

.age-modal-container{
    position: relative;
    max-width: 90vw;
    width: 1200px;
    height: 80vh;
    border-radius: 12px;
    border: unset;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    overflow: scroll;
    /*background-image: url('../../../Assets/ageVerify.png');*/
    background: rgb(109,221,160);
    background: linear-gradient(120deg, rgba(109,221,160,1) 0%, rgba(134,230,180,1) 100%);
    background-position: center;
    background-size: cover;
}

.discount-modal-container{
    position: relative;
    max-width: 90vw;
    width: 1200px;
    height: 80vh;
    border-radius: 12px;
    border: unset;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    overflow: scroll;
    background: rgb(109,221,160);
    background: linear-gradient(120deg, rgba(109,221,160,1) 0%, rgba(134,230,180,1) 100%);
    /*background-image: url('../../../Assets/15-off.jpg');
    background-position: right;
    background-size: cover;*/

}

.age-btn{
    background-color: #FF3F00;
    color: #77FD78;
    font-family: 'BigSans', sans-serif;
    border-radius: 15px;
    font-size: 64px;
    border: unset;
    padding: 20px 30px;
    margin: 20px;
}

#are-you{
    text-align: center;
    width: 700px;
    max-width: 90%;
    font-family: 'BigSans', sans-serif;
    font-size: 48px;
    color: #FF3F00;
    margin: 1rem auto;
}

.black-btn{
    background-color: black;
    color: white;
    height: 48px;
    border: unset;
    font-family: 'GreenGroveBold', serif;
    text-transform: uppercase;
    width: 150px;
    font-size: 20px;
}

#modal-input{
    border: 3px solid black;
    height: 56px;
    background-color: unset;
    width: 25vw;
    min-width: 200px;
    padding: 10px;
    text-transform: uppercase;
    font-family: 'GreenGroveBold', serif;
    color: black;
    font-size: 28px;
    padding-top: 15px;

}

#modal-input::placeholder{
    text-transform: uppercase;
    font-family: 'GreenGroveBold', serif;
    color: black;
    font-size: 28px;
    padding-top: 15px;
}

#discover-a-new{
    color: #FF3F00;
    font-family: 'GreenGroveBold', sans-serif;
    text-transform: uppercase;
    font-weight: 900;
    text-align:center;
    font-size: 28px;
}

.modal-copyright{
    font-family: 'BigSans', sans-serif;
    text-align: center;
    margin-top:30px;
    font-size: 16px;
}

@media only screen and (max-width: 600px) {
    .age-modal-container{
        padding: 1rem;
    }

    #are-you{
        font-size: 28px;
        margin: 0;
        width: 100%;
        max-width: 100%;
    }

    #you-must{
        font-size: 18px;

    }

    .age-btn{
        font-size: 42px;
        margin: 10px;
    }

    #modal-input{
        width: 100%;
        margin-right: 0;
        height: 48px;
        font-size: 22px;

    }

    #modal-input::placeholder{
        font-size: 22px;
    }

    .discount-modal-container{
        position: relative;
        max-width: 90vw;
        width: 1200px;
        height: 80vh;
        border-radius: 12px;
        border: unset;
        padding: 1rem;
        display: flex;
        flex-direction: column;
        overflow: scroll;
        background: rgb(109,221,160);
        background: linear-gradient(120deg, rgba(109,221,160,1) 0%, rgba(134,230,180,1) 100%);
    
    }
    .modal-btn{
        height: 48px !important;
        margin-bottom: 0 !important;
    }

    .modal-copyright{
        font-size: 14px;
    }
    

  }