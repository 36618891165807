#shipping{
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #77FD78;
    color: #FB4622;
    font-family: "futura-pt", sans-serif;
}

#van{
    height: 15px;
    object-fit: contain;
    margin-right: 10px;
}