#blogs{
    min-height: 75vh;
    padding: 75px 2rem 2rem 2rem;
    text-align: center;
}

#read-the{
    font-family: 'DesMontille';
    color: #B0F6FA;
    font-size: 109px;
    margin: 0;
    z-index: 4;
    position: relative;

}

#blog-txt{
    font-family: 'BigSans';
    color: #77FD78;
    font-size: 56px;
    margin: 0;
    position: relative;
    top: -40px;
    z-index: 3;

}

#blog-list{
    display: flex;
    flex-wrap: wrap;
    width: 1400px;
    max-width: 100%;
    margin: 0 auto;
    justify-content: space-between;
}

/* BLOG CARD */

.blog-card{
    width: 30%;
    height: 450px ;
    background-color: #ffb5ff;
    margin: 1rem;
    padding: 15px;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
    text-align: left;
    color: #FB4622;
    font-family: 'BigSans';
    text-transform: uppercase;
    position: relative;
}

.blog-link{
    text-transform: uppercase;
    font-family: 'Futura-PT', sans-serif;
    position: absolute;
    bottom: 15px;
    left: 15px;
    color: #FB4622;
    text-decoration: underline;
}

.blog-card-image{
    width: 100%;
    height: 275px;
    background-position: center;
    background-size: cover;
    position: relative;
}

.blog-category{
    padding: 10px 20px;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #ffb5ff;
}

/* BLOG Page */

#blog-page{
    padding: 90px 2rem 2rem 2rem;
}

@media only screen and (max-width: 1100px) {
    .blog-card{
        width: 46%;
    }
}

@media only screen and (max-width: 850px) {
   
    #read-the{
        font-size: 20vw;
    }
    
    #blog-txt{
        font-size: 10vw;
        top: -20px;
    }

    .blog-card{
        width: 100%;
    }

    #blogs{
        padding: 75px 0 2rem 0;
    }
    #blog-page{
        padding: 75px 1rem 1rem 1rem;
    }
  }